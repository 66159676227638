import React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'
import Hero from '../components/hero'
import Layout from '../components/layout'
import { Link } from 'gatsby'


import ToggleButton from '../components/toggle-button'


import ArticleListing from '../components/article-listing'

import ProductListing from '../components/product-listing'

import Img from 'gatsby-image'

import HomepageTileCollapsible from '../components/homepage-tile-collapsible'

import ContactCollapsible from '../components/contact-collapsible'

import MetadataGenerator from '../components/metadata-generator'


import Tile from '../components/tile'

import ContentTile from '../components/content-tile'

import UserGuide from '../components/user-guide'

import styles from '../components/sitemap.module.scss'

import LeadSection from '../components/lead-section'


//cannot have relative links for metadata 
import { Location } from '@reach/router'

import { useEffect } from 'react';
import { navigate } from "@reach/router"

export default () => {
  useEffect(() => {
    navigate('/');
  }, []);
  return null;
};

export const pageQuery = graphql`
  query notFoundQuery {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
    
  #start Page query
  allContentfulSupportSitePage {
    edges {
      node {
        title
        slug
        id
        createdAt (formatString: "X")
        updatedAt (formatString: "X")
        pageTopic {
          ... on ContentfulSupportSiteTopic {
            slug
          }
        }
        pageProduct {
          ... on ContentfulSupportSiteProduct {
            slug
          }
        }
        description {
          childMarkdownRemark {
            html
          }
        }
        body {
          json
        }
      }
    }
  }
  #end Page query

    contentfulSupportSiteOverview(title:{eq:"Overview"}) {
      id
    title
    homepageContentTiles {
      ... on ContentfulSupportSiteContentTile {
        id
        title
        description {
          childMarkdownRemark {
            html
          }
        }
        topic {
          title
          slug
        }
        url
        icon {
          file {
            url
            details {
              image {
                width
                height
              }
            }
          }
        }
        tileArticles {
          ... on ContentfulSupportSiteArticle {
            title
            slug
            articleCategoryTopic {
              slug
            }
            productReference {
              slug
            }
          }
          ... on ContentfulSupportSitePage {
            title
            slug
            pageTopic {
              slug
            }
            pageProduct {
              slug
            }
          }
        }
      }
    }
  }

    allContentfulSupportSiteArticle(sort: { fields: [updatedAt], order: DESC }) {
      edges {
        node {
          title
          slug
          id
          createdAt (formatString: "X")
          updatedAt (formatString: "X")
          description {
            childMarkdownRemark {
              html
            }
          }
          articleBody {
            json 
            content {
              content {
                value
              }
            }
          }
             productReference {
            ... on ContentfulSupportSiteProduct {
              title
              slug
            }
          }
             articleCategoryTopic {
            ... on ContentfulSupportSiteTopic {
              title
              slug
            }
          }
        }
      }
    }
    allContentfulSupportSiteTopic {
      edges {
        node {
          title
          slug
          id
        }
      }
    }
 #start product search
 allContentfulSupportSiteProduct(sort: { fields: [createdAt], order: ASC }) {
  edges {
    node {
      title
      slug
      id
      image {
        file {
          details {
            image {
              width
              height
            }
          }
          url
        }
      }
      homepageCategoryOrder {
        ... on ContentfulSupportSiteTopic {
          title
          slug
          icon {
            file {
              url
              details {
                image {
                  width
                  height
                }
              }
            }
          }
        }
      }
    }
  }
}
  #end product search
  contentfulAsset(file: {fileName: {eq: "youview-support-background.jpg"}}, fluid: {}) {
    fixed(width: 1800, quality: 100) {
      base64
      aspectRatio
      width
      height
      src
      srcSet
      srcWebp
      srcSetWebp
    }
  }

  }
`
